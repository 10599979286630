<template>
	<div class="card h-100">
		<img :src="propsImage" class="card-img-top" />
		<div class="card-body">
			<h3 class="card-title" v-html="propsName"></h3>
			<p class="card-text">
				<span v-if="propsZip">
					〒<span v-html="propsZip"></span><br />
				</span>
				<span v-html="propsAddress"></span>
			</p>
			<p v-if="propsTel" class="card-text">℡:{{ propsTel }}</p>
			<p v-if="propsTimes" class="card-text" v-html="propsTimes"></p>
		</div>
		<div
			class="card-footer bg-transparent d-flex justify-content-center flex-wrap"
		>
			<a
				v-if="propsUrl"
				class="col-12 col-lg-5 btn btn-sm btn-shop m-1 text-nowrap text-truncate"
				:href="propsUrl"
				>店舗サイト</a
			>
			<a
				v-if="propsDetailUrl"
				class="col-12 col-lg-5 btn btn-sm btn-shop m-1 text-nowrap text-truncate"
				:href="propsDetailUrl"
				>店舗詳細</a
			>
			<a
				v-if="propsTabelog"
				class="col-12 col-lg-5 btn btn-sm btn-tabelog m-1 text-nowrap text-truncate"
				:href="propsTabelog"
				>食べログ</a
			>
			<a
				v-if="propsGurunavi"
				class="col-12 col-lg-5 btn btn-sm btn-gurunavi m-1 text-nowrap text-truncate"
				:href="propsGurunavi"
				>ぐるなび</a
			>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		"propsImage",
		"propsName",
		"propsZip",
		"propsAddress",
		"propsTel",
		"propsTimes",
		"propsUrl",
		"propsDetailUrl",
		"propsTabelog",
		"propsGurunavi",
	],
};
</script>

<style scoped>
.card {
	box-shadow: 1px 1px 2px var(--light-gray);
}
.card-title,
.card-footer {
	font-family: "Kosugi Maru", sans-serif;
	font-weight: bold;
}
.card-img {
	height: 100%;
	object-fit: cover;
}

/* 風ボタン */
.btn-shop,
.btn-shop.disabled,
.btn-shop:disabled {
	color: #36bd65;
	border: #36bd65 2px solid;
}
.btn-shop:focus,
.btn-shop.focus,
.btn-shop:not(:disabled):not(.disabled):active:focus,
.btn-shop:not(:disabled):not(.disabled).active:focus,
.show > .btn-shop.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-shop:hover {
	color: #fff;
	background-color: #457a57;
	border-color: #3dcc6f;
}
.btn-shop:not(:disabled):not(.disabled):active,
.btn-shop:not(:disabled):not(.disabled).active,
.show > .btn-shop.dropdown-toggle {
	color: #fff;
	background-color: #00d149;
	border-color: #00d149;
}

/* 食べログボタン */
.btn-tabelog,
.btn-tabelog.disabled,
.btn-tabelog:disabled {
	color: #f29439;
	border: #f29439 2px solid;
}
.btn-tabelog:focus,
.btn-tabelog.focus,
.btn-tabelog:not(:disabled):not(.disabled):active:focus,
.btn-tabelog:not(:disabled):not(.disabled).active:focus,
.show > .btn-tabelog.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-tabelog:hover {
	color: #fff;
	background-color: #f29439;
	border-color: #f29439;
}
.btn-tabelog:not(:disabled):not(.disabled):active,
.btn-tabelog:not(:disabled):not(.disabled).active,
.show > .btn-tabelog.dropdown-toggle {
	color: #fff;
	background-color: #f29439;
	border-color: #f29439;
}

/* ぐるなび */
.btn-gurunavi,
.btn-gurunavi.disabled,
.btn-gurunavi:disabled {
	color: #d41222;
	border: #d41222 2px solid;
}
.btn-gurunavi:focus,
.btn-gurunavi.focus,
.btn-gurunavi:not(:disabled):not(.disabled):active:focus,
.btn-gurunavi:not(:disabled):not(.disabled).active:focus,
.show > .btn-gurunavi.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-gurunavi:hover {
	color: #fff;
	background-color: #d41222;
	border-color: #d41222;
}
.btn-gurunavi:not(:disabled):not(.disabled):active,
.btn-gurunavi:not(:disabled):not(.disabled).active,
.show > .btn-gurunavi.dropdown-toggle {
	color: #fff;
	background-color: #d41222;
	border-color: #d41222;
}
</style>

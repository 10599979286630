<template>
	<section id="ramen" class="mb-content">
		<TitleGroup MainTitle="東京麻辣湯" SubTitle="麻辣湯へのこだわり" />

		<div class="content px-2 px-sm-0 ms-sm-5 py-2 py-sm-5">
			<p>
				厳選された地元の具材とスープの辛さを選んで あなただけの一杯を
				<br class="d-sm-none" />お楽しみください!<br />
				薬膳スープ春雨専門店「東京麻辣湯も
				<br class="d-sm-none" />
				是非よろしくお願い致します。
			</p>
		</div>

		<div class="col-12 col-xl-4 mx-auto">
			<img src="img/cook/maratan03.jpg" alt="麻辣湯" class="img-fluid" />
		</div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	components: {
		TitleGroup,
	},
};
</script>

<style scoped>
.img-box {
	box-shadow: 3px 3px 2px var(--light-gray);
}
</style>

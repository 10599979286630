<template>
	<section id="beef" class="mb-content">
		<TitleGroups MainTitle="焼肉Yaホ！" SubTitle="自慢の黒毛和牛" />

		<div class="content px-2 px-sm-0 ms-sm-5 py-2 py-sm-5">
			<p>
				食べ飲み放題プラン : <br class="d-sm-none" />
				飲み放題、食べ放題全１３０品!<br />
				A5ランク最高級黒毛和牛ステーキ・カルビ、牛タン塩、はらみやホルモンなど全１３０品が１２０分間食べ飲み放題!!<br />
				Yaホだからこそ最高のコスパに挑戦!<br class="d-sm-none" />
				人気No.1の大人気コースです。<br />
				ご予約は2名様～忘年会や歓送迎会などにもオススメです
			</p>
		</div>

		<Sliders />
	</section>
</template>

<script>
import TitleGroups from "../Molecules/TitleGroups.vue";
import Sliders from "../Molecules/Sliders.vue";

export default {
	components: {
		Sliders,
		TitleGroups,
	},
};
</script>

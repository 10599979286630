<template>
	<section id="company">
		<TitleGroup MainTitle="会社概要" />
		<div class="ms-sm-3 my-3 my-sm-5">
			<table class="table">
				<tbody>
					<tr>
						<th>代表取締役</th>
						<td>金義佳祐</td>
					</tr>
					<tr>
						<th>住所</th>
						<td>〒260-0834<br />千葉県千葉市中央区今井1-5-27</td>
					</tr>
					<tr>
						<th>事業内容</th>
						<td>
							外食事業【焼肉Yaホ!・東京麻辣湯】/ 輸入・貿易業 /
							食材輸入販売業 / 食品製造販売業
						</td>
					</tr>
					<tr>
						<th>従業員数</th>
						<td>55名(2021年1月時点) ※正社員、パート合計人数</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	components: {
		TitleGroup,
	},
};
</script>

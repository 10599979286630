<template>
	<section id="shops">
		<TitleGroup MainTitle="店舗紹介" />

		<h3 class="yaho mt-5 p-2 ps-3">焼肉Yaホ！</h3>
		<div class="row card-group row-cols-1 row-cols-lg-3 p-3">
			<div
				class="col my-2"
				v-for="shop in ViewShopsYaho"
				:key="shop.Name"
			>
				<ShopGroup
					:propsImage="shop.Image"
					:propsName="shop.Name"
					:propsZip="shop.Zip"
					:propsAddress="shop.Address"
					:propsTel="shop.Tel"
					:propsTimes="shop.Times"
					:propsUrl="shop.Url"
					:propsDetailUrl="shop.DetailUrl"
					:propsTabelog="shop.Tabelog"
					:propsGurunavi="shop.Gurunavi"
				/>
			</div>
		</div>

		<h3 class="ramen mt-5 p-2 ps-3">東京麻辣湯</h3>
		<div class="row card-group row-cols-1 row-cols-lg-3 p-3">
			<div
				class="col my-2"
				v-for="shop in ViewShopsRamen"
				:key="shop.Name"
			>
				<ShopGroup
					:propsImage="shop.Image"
					:propsName="shop.Name"
					:propsZip="shop.Zip"
					:propsAddress="shop.Address"
					:propsTel="shop.Tel"
					:propsTimes="shop.Times"
					:propsUrl="shop.Url"
					:propsDetailUrl="shop.DetailUrl"
					:propsTabelog="shop.Tabelog"
					:propsGurunavi="shop.Gurunavi"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";
import ShopGroup from "../Molecules/ShopGroups.vue";

export default {
	computed: {
		ViewShopsYaho() {
			return this.$store.getters["shops/ViewShopsYaho"];
		},
		ViewShopsRamen() {
			return this.$store.getters["shops/ViewShopsRamen"];
		},
		ViewShopsHan() {
			return this.$store.getters["shops/ViewShopsHan"];
		},
	},
	components: {
		TitleGroup,
		ShopGroup,
	},
};
</script>

<style scoped>
h3 {
	font-family: "Kosugi Maru", sans-serif;
	font-weight: bold;
}
.yaho {
	color: white;
	background-color: brown;
}
.ramen {
	color: white;
	background-color: crimson;
}
.han {
	color: white;
	background-color: seagreen;
}
</style>

<template>
	<section id="news" class="contents mb-content">
		<TitleGroup MainTitle="ニュース" />
		<div v-html="NewsContents"></div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	props: { NewsContents: String },
	components: {
		TitleGroup,
	},
};
</script>

<template>
	<h2 class="category-title h1"><slot></slot></h2>
</template>

<script>
export default {
	name: "ContentTitle",
};
</script>

<style>
.category-title {
	font-family: "Kosugi Maru", sans-serif;
	font-weight: bold;
	position: relative;
	padding: 35px 20px 0 30px;
	margin: 20px 0 0 10px;
}
.category-title:before {
	content: "";
	background-color: rgba(180, 180, 250, 1);
	display: block;
	position: absolute;
	left: 0;
	height: 90px;
	width: 90px;
	border-radius: 50%;
	top: 0;
	z-index: -1;
}
@media (max-width: 980px) {
	.category-title {
		padding: 35px 0 0 15px;
		margin: 10px 0 0 0;
	}
}
</style>

<template>
	<section id="origin" class="mb-content">
		<TitleGroup MainTitle="カネヨシ原点" SubTitle="伝統ここから始まる" />

		<img src="img/kaneyoshi01.jpg" class="img-fluid mt-5" alt="" />

		<p class="mt-2 mt-sm-5 text-sm-center">
			伝統の味を受け継ぐ<br />
			花咲く食卓<br />
			「韓の花」は四十年の歴史とノウハウが詰まった<br />
			「カネヨシ商店」から始まった<br />
			<br />
			本場の味にこだわり<br />
			五味五色のパンチャン〝お惣菜"で<br />
			健康と笑顔を守りたい
		</p>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	components: {
		TitleGroup,
	},
};
</script>

<style scoped>
p {
	font-size: 24px;
}
@media (max-width: 980px) {
	p {
		padding: 10px;
		font-size: 18px;
	}
}
</style>

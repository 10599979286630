const state = {
	History: [
		{
			Times: "1982年12月",
			Content: "焼肉卸　キムチの店　金義商店　創業",
		},
		{
			Times: "2010年8月",
			Content: "焼肉Yaホ!中央本店　開店",
		},
		{
			Times: "2012年8月",
			Content: "株式会社カネヨシ 設立",
		},
		{
			Times: "2012年9月",
			Content: "焼肉Yaホ!富士見店　開店",
		},
		{
			Times: "2013年5月",
			Content: "市原セントラルキッチン完成",
		},
		{
			Times: "2014年12月",
			Content: "焼肉Yaホ!姉ヶ崎店　開店",
		},
		{
			Times: "2018年4月",
			Content: "東京麻辣湯小岩店 開店",
		},
		{
			Times: "2020年8月",
			Content: "株式会社 KANEYOSHI KOREA 韓国支店 設立",
		},
		{
			Times: "2020年12月",
			Content: "東京麻辣湯 ペリエ千葉 開店",
		},
		{
			Times: "2021年11月",
			Content: "ペリエ西千葉 ANNEX館 韓ステーション OPEN",
		},
		{
			Times: "2022年6月",
			Content: "花咲く食卓 韓の花 ペリエ千葉 OPEN",
		},
		{
			Times: "2023年12月",
			Content: "東京麻辣湯 アクアシティお台場 OPEN",
		},
	],
};
const getters = {
	ViewHistory(state) {
		return state.History;
	},
};
const mutations = {};
const actions = {};
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "swiper/css/swiper.css";
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueScrollTo from "vue-scrollto";
import App from "./App.vue";
import store from "./store";

Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
	store,
	render: (h) => h(App),
}).$mount("#app");

const state = {
	ShopsYaho: [
		{
			Name: "焼肉Yaホ！<br>富士見店",
			Image: "img/shop/yaho_hujimi.jpg",
			Zip: "260-0014",
			Address: "千葉市中央区本千葉町1-4",
			Tel: "043-227-2939",
			Times: "月～土 / 17:00～24:00(LO:23:00)<br/>日・祝 / 17:00～23:00(LO:22:00)",
			Url: "https://fujimi.yakiniku-yaho.jp/?utm_source=home&utm_medium=banner",
			DetailUrl: "https://yakinikuyaho-fujimi.owst.jp/",
			Tabelog: "https://tabelog.com/chiba/A1201/A120101/12029892/",
			Gurunavi: "https://r.gnavi.co.jp/gcuk900/",
		},
		{
			Name: "焼肉Yaホ！<br>姉ヶ崎店",
			Image: "img/shop/yaho_anegasaki.jpg",
			Zip: "299-0110",
			Address: "千葉県市原市姉崎東1丁目5-1",
			Tel: "0436-37-5829",
			Times: "16:00～22:30(L.O)<br>定休日:不定休",
			Url: "https://anesaki.yakiniku-yaho.jp/?utm_source=home&utm_medium=banner",
			DetailUrl: "https://yakinikuyaho-anegasaki.owst.jp/",
			Tabelog: "https://tabelog.com/chiba/A1206/A120601/12038081/",
			Gurunavi: "https://r.gnavi.co.jp/jye0bab30000/",
		},
		// {
		// 	Name: "焼肉Yaホ！<br>中央店",
		// 	Image: "img/shop/yaho_chuou.jpg",
		// 	Zip: "260-0013",
		// 	Address: "千葉市中央区中央2丁目7-7-10",
		// 	Tel: "043-222-2929",
		// 	Times: "月～土 / 17:00～24:00(LO:23:00)<br>祝 / 17:00～23:00(LO:22:00)<br>定休日:日曜日",
		// 	Url: "",
		// 	DetailUrl: "",
		// 	Tabelog: "https://tabelog.com/chiba/A1201/A120101/12024136/",
		// 	Gurunavi: "https://tabelog.com/chiba/A1201/A120101/12024136/",
		// },
	],
	ShopsRamen: [
		{
			Name: "東京麻辣湯<br>アクアシティお台場",
			Image: "img/shop/maratan_odaiba.jpg",
			Zip: "",
			Address: "アクアシティお台場 5F",
			Tel: "",
			Times: "11:00～23:00<br>(L.O.22:00)",
			Url: "",
			DetailUrl: "https://aquacity.jp/sp/news/detail.php?id=58933",
			Tabelog: "",
			Gurunavi: "",
		},
		{
			Name: "東京麻辣湯<br>PERIE千葉",
			Image: "img/shop/maratan_perichika.jpg",
			Zip: "260-0031",
			Address: "千葉県千葉市中央区新千葉1-1-1<br>ペリエ千葉 B1Fペリチカ",
			Tel: "043-307-8859",
			Times: '月～土 / 10:00～21:00<br>日・祝 / 10:00～20:30<br><a href="https://www.perie.co.jp/chiba/access/time.html" class="btn btn-sm btn-secondary">PERIE千葉ペリチカ営業時間</a>',
			Url: "",
			DetailUrl:
				"https://perie01.tokyomaratan.com/?utm_source=home&utm_medium=banner",
			Tabelog: "",
			Gurunavi: "",
		},
	],
	// ShopsHan: [
	// 	{
	// 		Name: "花咲く食卓<br>韓の花",
	// 		Image: "img/shop/kannohana.jpg",
	// 		Zip: "260-0031",
	// 		Address: "千葉県千葉市中央区新千葉1-1-1<br>ペリエ千葉 B1Fペリチカ",
	// 		Tel: "043-441-8816",
	// 		Times: '月～土 / 10:00～21:00<br>日・祝 / 10:00～20:30<br><a href="https://www.perie.co.jp/chiba/access/time.html" class="btn btn-sm btn-secondary">PERIE千葉ペリチカ営業時間</a>',
	// 		Url: "",
	// 		DetailUrl:
	// 			"https://kan01.yakiniku-yaho.jp/?utm_source=home&utm_medium=banner",
	// 		Tabelog: "",
	// 		Gurunavi: "",
	// 	},
	// {
	// 	Name: "ペリエ西千葉<br>韓ステーション",
	// 	Image: "img/shop/perie_nishichiba.jpg",
	// 	Zip: "260-0013",
	// 	Address: "千葉市中央区春日2丁目24番4号<br>ペリエ西千葉アネックス館",
	// 	Times: "月～土 / 10:00～20:00<br>日・祝 / 10:00～20:00",
	// 	Url: "https://hanst01.yakiniku-yaho.jp/?utm_source=home&utm_medium=banner",
	// 	DetailUrl: "",
	// 	Tabelog: "",
	// 	Gurunavi: "",
	// },
	// ],
};
const getters = {
	ViewShopsYaho(state) {
		return state.ShopsYaho;
	},
	ViewShopsRamen(state) {
		return state.ShopsRamen;
	},
	ViewShopsHan(state) {
		return state.ShopsHan;
	},
};
const mutations = {};
const actions = {};
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

<template>
	<div class="page-content w-100 h-100">
		<nav class="navbar px-3 bg-light border-bottom shadow-sm sticky-top">
			<div class="navbar-brand toggle-menu d-flex align-items-center">
				<button class="btn btn-light py-0" @click="BtnClick">
					<i class="fas fa-bars fa-lg"></i>
				</button>
				<h1 class="h5 p-0 m-0">株式会社カネヨシ</h1>
			</div>
		</nav>

		<div class="container-fuild bg">
			<Top />
			<News :NewsContents="htmlContent" />
			<Origin />
			<Beef />
			<Ramen />
			<!-- <Hana /> -->
			<Shops />
			<Company />
			<History />
		</div>

		<footer class="footer mt-sm-4 py-sm-3 bg-light">
			<div class="container text-center">
				<span class="text-muted"
					>2019 Yaho Inc. &copy; All Right Reserved.</span
				>
			</div>
		</footer>
	</div>
</template>

<script>
import Beef from "../Organisms/Beef";
import Company from "../Organisms/Company";
import History from "../Organisms/History";
import News from "../Organisms/News";
import Origin from "../Organisms/Origin";
import Ramen from "../Organisms/Ramen";
import Shops from "../Organisms/Shops";
import Top from "../Organisms/Top";
import Hana from "../Organisms/Hana.vue";

export default {
	data() {
		return {
			htmlContent: "",
		};
	},
	methods: {
		BtnClick(value) {
			this.$emit("my-click", value);
		},
		loadHtml() {
			fetch("./html/news.html").then((res) => {
				res.text().then((html) => {
					this.htmlContent = html;
				});
			});
		},
	},
	mounted: function () {
		this.loadHtml();
	},
	components: {
		Top,
		Origin,
		News,
		Beef,
		Ramen,
		Shops,
		History,
		Company,
		Hana,
	},
};
</script>

<style scoped>
.page-content {
	transition: all 0.5s 0s ease;
}
.bg {
	background-color: rgba(255, 255, 255, 1);
	background-image: url("~@/assets/bg.jpg");
	z-index: -5;
}
</style>

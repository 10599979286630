<template>
	<div id="app">
		<div class="page-wrapper d-flex flex-row h-100 w-100">
			<transition name="slide">
				<SideMenu
					@menu-click="HideMenu"
					class="side-menu"
					v-show="SideBar"
				/>
			</transition>

			<Main
				@my-click="ToggleMenu"
				:style="{ 'padding-left': MainPadding }"
			/>
		</div>
	</div>
</template>

<script>
import SideMenu from "./components/Organisms/SideMenu.vue";
import Main from "./components/Templates/Main.vue";

export default {
	name: "App",
	data() {
		return {
			SideBar: true,
			MainPadding: "250px",
		};
	},
	methods: {
		ToggleMenu() {
			this.SideBar = !this.SideBar;
			if (window.innerWidth > 960) {
				if (this.MainPadding == 0) {
					this.MainPadding = "250px";
				} else {
					this.MainPadding = 0;
				}
			}
		},
		HideMenu(value) {
			if (window.innerWidth < 960) {
				this.SideBar = value;
			}
		},
	},
	mounted() {
		if (window.innerWidth < 960) {
			this.SideBar = false;
			this.MainPadding = 0;
		}
	},
	components: {
		Main,
		SideMenu,
	},
};
</script>

<style>
:root {
	--black: rgba(75, 90, 107, 1);
	--dark-gray: rgba(92, 92, 92, 1);
	--gray: rgba(140, 140, 140, 1);
	--light-gray: rgba(200, 200, 200, 1);
	--white: rgba(253, 253, 255, 1);
	--orange: #ff8c2f;
	--top_color1: #092a62;
}

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	color: var(--dark-gray);

	font-family: "Noto Sans JP", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	letter-spacing: 0.12rem;
	overflow-x: hidden;
}
.main {
	padding-left: 250px;
}
.side-menu {
	z-index: 9999;
}
.content {
	font-family: "Kosugi Maru", sans-serif;
}
.mb-content {
	padding-bottom: 130px;
}

.slide-enter-active {
	animation: slide 0.5s reverse;
}
.slide-leave-active {
	animation: slide 0.5s ease;
}
@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-250px);
	}
}
@media (max-width: 980px) {
	.side-menu {
		margin-top: 55px;
	}
}
</style>

<template>
	<section id="kannohana" class="mb-content">
		<TitleGroup
			MainTitle="韓の花"
			SubTitle="本格韓国惣菜　パンチャン　お弁当"
		/>

		<div class="content px-2 px-sm-0 ms-sm-5 py-2 py-sm-5">
			<p>彩り豊かで健康に 会話も花咲く韓国お惣菜</p>
		</div>

		<div class="col-12 col-xl-4 mx-auto">
			<img
				src="img/shop/kannohana_shop.jpg"
				alt="韓の花"
				class="img-fluid"
			/>
		</div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	components: {
		TitleGroup,
	},
};
</script>

<template>
	<div
		class="d-sm-flex align-items-end mb-3 border-bottom border-dark clearfix"
	>
		<ContentTitle>{{ MainTitle }}</ContentTitle>
		<ContentSubTitle v-if="SubTitle">{{ SubTitle }}</ContentSubTitle>
	</div>
</template>

<script>
import ContentTitle from "../Atoms/ContentTitle";
import ContentSubTitle from "../Atoms/ContentSubTitle";

export default {
	props: ["MainTitle", "SubTitle"],
	components: {
		ContentTitle,
		ContentSubTitle,
	},
};
</script>

<template>
	<swiper :options="swiperOption">
		<swiper-slide>
			<img src="img/cook/cook1.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook2.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook3.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook4.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook5.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook6.jpg" class="img-fluid" />
		</swiper-slide>
		<swiper-slide>
			<img src="img/cook/cook7.jpg" class="img-fluid" />
		</swiper-slide>
	</swiper>
</template>

<script>
export default {
	data() {
		return {
			swiperOption: {
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
				},
				speed: 1000,
				slidesPerView: 3,
				loop: true,
				breakpoints: {
					980: {
						slidesPerView: 6,
					},
				},
			},
		};
	},
};
</script>
